import Alpine from "alpinejs";

window.Alpine = Alpine;
Alpine.start();

window.addEventListener('scroll', function() {
    const navbar = document.getElementById('navbar');
    if (window.scrollY > 0) {
        navbar.classList.remove('header');
        navbar.classList.add('header-fixed');
    } else {
        navbar.classList.remove('header-fixed');
        navbar.classList.add('header');
    }
});

 // Toggle body scroll
 window.toggleBodyScroll = function() {
    var body = document.body;
    body.style.overflow = body.style.overflow === 'hidden' ? 'auto' : 'hidden';
};
